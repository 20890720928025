const resetInputsWithin = (element) => {
  element
    .querySelectorAll('input[type="checkbox"], input[type="radio"]')
    .forEach((el) => {
      el.checked = false;
      el.selected = false;
    });

  element
    .querySelectorAll('input:not([type="checkbox"]), input:not([type="radio"])')
    .forEach((el) => (el.value = ""));

  element
    .querySelectorAll('option[selected="selected"]')
    .forEach((el) => el.removeAttribute("selected"));
};

const recalculateArrayIndexes = (element, i, n) => {
  element.setAttribute("data-item-index", n);
  element.querySelectorAll(`[name*="${i}"]`).forEach((el) => {
    el.setAttribute(
      "name",
      el.getAttribute("name").replace(`[${i}]`, `[${n}]`)
    );
    el.setAttribute("id", el.getAttribute("id").replace(`${i}`, `${n}`));
  });
};

const COMPONENT = '[data-component="form-repeater"]';
const CONTAINER = '[data-component-target="form-repeater.container"]';
const ADD_NEW = '[data-component-target="form-repeater.add"]';
const DELETE = '[data-component-target="form-repeater.delete"]';
const ITEM = '[data-component-target="form-repeater.item"]';

const handleDelete = (e) => {
  const item = e.target.closest(ITEM);
  if (!item) {
    return;
  }

  resetInputsWithin(item);
  item.classList.add("dn");
  item.classList.remove("flex");
  item
    .querySelectorAll("[required]")
    .forEach((el) => el.removeAttribute("required"));
};

export default () => {
  document.querySelectorAll(COMPONENT).forEach((cmp) => {
    const container = cmp.querySelector(CONTAINER);
    const addButton = cmp.querySelector(ADD_NEW);
    const deleteButtons = cmp.querySelectorAll(DELETE);

    addButton.addEventListener("click", () => {
      const item = cmp.querySelector(`${ITEM}:last-child`);
      if (!item) {
        return;
      }

      const idx = item.getAttribute("data-item-index");
      const ndx = parseInt(idx, 10) + 1;
      const newChild = item.cloneNode(true);

      resetInputsWithin(newChild);
      recalculateArrayIndexes(newChild, idx, ndx);

      container.appendChild(newChild);
      newChild.querySelector(DELETE).addEventListener("click", handleDelete);
    });

    deleteButtons.forEach((el) => el.addEventListener("click", handleDelete));
  });
};
