export default () => {
  const portalInputs = document.querySelectorAll("[data-image-portal]");

  portalInputs.forEach((el) => {
    el.addEventListener("input", (e) => {
      const el = e.target;
      const targetEls = document.querySelectorAll(
        `[data-image-portal-observe="${el.getAttribute("data-image-portal")}"]`
      );
      targetEls.forEach((tel) => {
        if (FileReader && e.target.files && e.target.files.length) {
          const fr = new FileReader();
          fr.onload = (fe) => {
            if (tel.tagName === "IMG") {
              tel.src = fe.target.result;
            } else {
              tel.style.backgroundImage = `url(${fe.target.result})`;
              tel.innerHTML = ""; // Clear the placeholder image
            }
          };
          fr.readAsDataURL(e.target.files[0]);
        }
      });
    });
  });
};
