import autocomplete from "autocompleter";
import countryData from "./continents";

const createValue = (value, index) => {
  return `<input type="hidden" name="AvailableIn[${index}]" value="${value}"></input>`;
};

const createItem = (item, nextIndex, onRemove) => {
  let value = createValue(item.value, nextIndex);

  if (Array.isArray(item.value)) {
    value = item.value
      .map((item, index) => createValue(item.value, nextIndex + index))
      .join("");
  }

  const el = document.createElement("li");
  el.className =
    "black bg-near-white items-center br3 flex justify-between mr2 mb2";
  el.innerHTML = `
    ${value}
    <span class="pa1 pa3-ns">${item.label}</span>
  `;

  const btn = document.createElement("button");
  btn.type = "button";
  btn.innerText = "✕";
  btn.className =
    "red b dib link bg-transparent bw0 pointer dim ma0 pa1 pa3-ns";
  btn.addEventListener("click", (e) => {
    el.remove();
    onRemove();
  });

  el.appendChild(btn);

  return el;
};

const recalculateIndexes = (results) => () => {
  console.log(results.querySelectorAll('input[type="hidden"]'));
  results.querySelectorAll('input[type="hidden"]').forEach((el, index) => {
    el.name = `AvailableIn[${index}]`;
  });
};

export default () => {
  const continents = {};
  const countries = countryData.map((item) => {
    if (!continents[item.continent]) {
      continents[item.continent] = {
        label: item.continent,
        value: [item],
      };
    } else {
      continents[item.continent].value.push(item);
    }

    return {
      label: item.label,
      value: item.value,
    };
  });

  document
    .querySelectorAll('[data-component="territory-picker"]')
    .forEach((el) => {
      const input = el.querySelector('[data-target="territory-picker.input"]');
      const results = el.querySelector('[data-target="territory-picker.list"]');

      autocomplete({
        input,
        fetch: (text, update) => {
          update(
            Object.values(continents)
              .concat(countries)
              .filter((n) =>
                n.label.toLowerCase().startsWith(text.toLowerCase())
              )
          );
        },
        onSelect: (item) => {
          const nextIndex = results.querySelectorAll("li").length;
          results.appendChild(
            createItem(item, nextIndex, recalculateIndexes(results))
          );
          input.value = null;
        },
        minLength: 2,
        className: "pa3 z-max bg-white shadow-xl br3",
        render: function (item, currentValue) {
          const itemElement = document.createElement("div");
          itemElement.textContent = item.label;
          itemElement.className =
            "pv3 hover-gold relative bb b--black-10 pointer";
          return itemElement;
        },
      });
    });
};
