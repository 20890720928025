const LB = 454.0;
const OZ = 28.35;
const G = 1;
const KG = 1000;

const convertValueToGrams = (input, from) => {
  var val = input;

  switch (from.toLowerCase()) {
    case "lb":
      val = input * LB;
      break;
    case "oz":
      val = input * OZ;
      break;
    case "kg":
      val = input * KG;
      break;
    default:
      break;
  }

  return Math.ceil(val);
};

const convertValueFromGrams = (input, to) => {
  var val = input;

  switch (to.toLowerCase()) {
    case "lb":
      val = input / LB;
      break;
    case "oz":
      val = input / OZ;
      break;
    case "kg":
      val = input / KG;
      break;
    default:
      break;
  }

  return Math.ceil(val);
};

export default () => {
  document.querySelectorAll('[data-component="weight-unit"]').forEach((el) => {
    const textInput = el.querySelector('input[type="text"]');
    const hiddenValueInput = el.querySelector(
      '[data-component-element="value"]'
    );
    const hiddenUnitInput = el.querySelector('[data-component-element="unit"]');
    const unit = el.querySelector("select");

    textInput.addEventListener("input", (event) => {
      const val = parseFloat(textInput.value);
      hiddenValueInput.value = convertValueToGrams(
        val,
        unit.options[unit.selectedIndex].value
      );
      hiddenUnitInput.value = "g";
    });

    unit.addEventListener("change", (event) => {
      const val = parseFloat(textInput.value);
      hiddenValueInput.value = convertValueToGrams(
        val,
        unit.options[unit.selectedIndex].value
      );
      hiddenUnitInput.value = "g";
    });

    const preferredUnit = el.getAttribute("data-preferred-unit");
    unit.selectedIndex = [...unit.options]
      .map((item) => item.value)
      .indexOf(preferredUnit);
    const val = parseFloat(hiddenValueInput.value);
    textInput.value = convertValueFromGrams(val, preferredUnit);
  });
};
