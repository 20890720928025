const attr = "data-price-portal-target";

export default () => {
  document.querySelectorAll(`[${attr}]`).forEach((el) => {
    const item = document.querySelector(el.getAttribute(attr));
    if (!item) {
      return;
    }

    el.value = (item.value / 100).toFixed(2);

    el.addEventListener("change", (e) => {
      const item = document.querySelector(el.getAttribute(attr));
      if (!item) {
        return;
      }

      item.value = parseFloat(e.target.value) * 100;
    });
  });
};
