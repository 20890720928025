export default () => {
  const portalInputs = document.querySelectorAll("[data-portal]");

  portalInputs.forEach((el) => {
    const EVENT = el.tagName === "SELECT" ? "change" : "keyup";

    el.addEventListener(EVENT, (e) => {
      const el = e.target;
      const targetEls = document.querySelectorAll(
        `[data-portal-observe="${el.getAttribute("data-portal")}"]`
      );
      targetEls.forEach((tel) => (tel.textContent = el.value));
    });
  });
};
