// MAX_FILE_SIZE defines maximum of 1.%MB allowed in file size.
const MAX_FILE_SIZE = 1572864;

// getPath returns an URL to send the file to, including the designated file name
const getPath = (prefix, name) => `${APP_URL}/image-uploads/${prefix}-${name}`;

const validateFile = (file) => {
  if (file.size < MAX_FILE_SIZE) {
    return;
  }

  throw new Error(
    "File must not be larger than 1.5MB. We recommend images with 1500 pixels in width"
  );
};

export default () => {
  document.querySelectorAll('[data-component="image-upload"]').forEach((el) => {
    const filePrefix = el.getAttribute("data-file-prefix");
    const btn = el.querySelector('button[type="button"]');
    const img = el.querySelector("img");
    const hiddenInput = el.querySelector('input[type="hidden"]');
    const fileInput = el.querySelector('input[type="file"]');
    const errorContainer = el.querySelector(
      '[data-target="image-upload.error"]'
    );

    // Display the button
    fileInput.addEventListener("input", () => {
      try {
        validateFile(fileInput.files[0]);

        btn.disabled = false;
        btn.innerText = "Upload";
        btn.classList.remove("dn");
        btn.classList.remove("bg-red");
        btn.classList.add("bg-gold");
        errorContainer.innerText = "";
      } catch (error) {
        errorContainer.innerText = error.message;
        btn.innerText = "Error";
        btn.classList.remove("bg-gold");
        btn.classList.add("bg-red");
        console.log(error);
      }
    });

    // Start uploading
    btn.addEventListener("click", async (e) => {
      e.target.disabled = true;
      e.target.innerText = "Uploading…";

      try {
        const files = fileInput.files;
        if (files.length === 0) {
          return;
        }

        const file = files[0];

        const formData = new FormData();
        formData.append("file", file);

        const response = await fetch(getPath(filePrefix, file.name), {
          method: "POST",
          body: formData,
        });

        const { _, file_url } = await response.json();

        img.src = file_url;
        hiddenInput.value = file_url;
        // Hide the button after
        e.target.classList.add("dn");
      } catch (error) {
        errorContainer.innerText = error.message;
        e.target.innerText = "Error";
        e.target.classList.remove("bg-gold");
        e.target.classList.add("bg-red");
        console.log(error);
      }
    });
  });
};
