export default () => {
  const triggerInputs = document.querySelectorAll(".reset-toggle-checked");

  triggerInputs.forEach((el) => {
    const EVENT = "change";

    el.addEventListener(EVENT, (e) => {
      if (e.target.checked) {
        triggerInputs.forEach((nel) => {
          if (!nel.isEqualNode(e.target)) {
            nel.checked = false;
            var event = document.createEvent("HTMLEvents");
            event.initEvent("change", false, true);
            nel.dispatchEvent(event);
          }
        });
      }
    });
  });
};
