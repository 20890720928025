export default () => {
  const portalInputs = document.querySelectorAll("[data-color-portal]");

  portalInputs.forEach((el) => {
    el.addEventListener("input", (e) => {
      const el = e.target;
      const targetEls = document.querySelectorAll(
        `[data-color-portal-observe="${el.getAttribute("data-color-portal")}"]`
      );
      targetEls.forEach((tel) => {
        const key = tel.getAttribute("data-color-portal-key") || "color";
        tel.style[key] = el.value;
      });
    });
  });
};
