import colorPortals from "./components/color-portals";
import detailsToggle from "./components/details-toggle";
import formRepeater from "./components/form-repeater";
import imagePortals from "./components/image-portals";
import imageUpload from "./components/image-upload";
import portals from "./components/portals";
import pricePortals from "./components/price-portals";
import territoryPicker from "./components/territory-picker";
import togglePanels from "./components/toggle-panels";
import weightUnit from "./components/weight-unit";

window.addEventListener("load", () => {
  colorPortals();
  detailsToggle();
  formRepeater();
  imagePortals();
  imageUpload();
  portals();
  pricePortals();
  territoryPicker();
  togglePanels();
  weightUnit();
});
